import React from "react";
import { PageLayout, MenuFilesDisplay } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class GroupMenuPage extends React.PureComponent {
  render() {
    return (
      <WebsiteLayout>
        <PageLayout className={"group-menu-page"} hero={null}>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <MenuFilesDisplay
                files={[
                  {
                    url: "https://fisherman.gumlet.io/public/concerthallandbarreltavern/Concert_Hall_and_Barrel_Tavern_Group_Menu_2021_hBoWV35.pdf",
                    filename: "Menu",
                  },
                ]}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}
